import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Main() {
  const history = useHistory();
  const { t } = useTranslation();

  const handleOnSignin = () => {
    history.push('/signin');
  };

  return (
    <div className='p-3'>
      <h1 className='text-center mb-5'>{t('main.title')}</h1>
      <p className='text-center'>{t('main.text')}</p>
      <p className='text-center'>
        <button
          type='button'
          className='btn btn-primary'
          onClick={handleOnSignin}
        >
          {t('main.btn')}
        </button>
      </p>
    </div>
  );
}

export default Main;
