import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropzone from 'react-dropzone';
import Alert from 'react-s-alert';

import ImageService from './../services/image.service';
import { ImageResponseDto } from '../common/dto';

type ImageLoaderProps = {
  content?: ImageResponseDto | undefined;
  handleChange: (value: ImageResponseDto | undefined) => void;
};

const acceptTypes = ['image/jpeg', 'image/png', 'image/jpg'];
const limitFileSize = 3000000; // 3 MB

const ImageLoader: React.FunctionComponent<ImageLoaderProps> = ({
  content,
  handleChange,
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState<ImageResponseDto | undefined>(content);

  const uploadFile = (acceptedFiles: any) => {
    if (!acceptedFiles[0]) {
      Alert.info(t('imageLoader.fileError'));
      return;
    }

    // create form data
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    // service upload
    ImageService.upload(formData)
      .then(r => {
        handleChange(r.data);
        setImage(r.data);
      })
      .catch(e => {
        console.log('e', e);
      });
  };

  const handleDelete = (key: string) => {
    // service delete
    ImageService.delete(key)
      .then(() => {
        handleChange(undefined);
        setImage(undefined);
      })
      .catch(e => {
        console.log('e', e);
      });
  };

  return (
    <>
      <Dropzone
        onDrop={acceptedFiles => uploadFile(acceptedFiles)}
        accept={acceptTypes.join(',')}
        maxSize={limitFileSize}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <section className='dropzone mb-3'>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <span>{t('imageLoader.text')}</span>
            </div>
          </section>
        )}
      </Dropzone>

      {image && (
        <div className='imageLoaderImageWr'>
          <img
            alt='uploadedImg'
            src={`${image.location}`}
            className='imageLoaderImage'
          />
          <span
            onClick={() => {
              handleDelete(image.key);
            }}
          >
            <i className='bi bi-x-lg' />
          </span>
        </div>
      )}
    </>
  );
};

export default ImageLoader;
