import React from 'react';
import { useTranslation } from 'react-i18next';

// DTO
import { ImageResponseDto, OptionDto, QuestionType } from './../../common/dto';

// UI COMPONENTS
import {
  InputLabel,
  FormTextInput,
  Editor,
  ImageLoader,
} from '../../components';

import Direction from './Direction';

interface ICreateInstitutionFormProps {
  questionType: QuestionType;
  errors?: any;
  payload?: OptionDto;
  handleChange: Function;
}

const OptionForm = ({
  questionType,
  errors,
  payload,
  handleChange,
}: ICreateInstitutionFormProps) => {
  const { t } = useTranslation();

  if (!payload) return null;

  return (
    <>
      <div className='row'>
        <div className='col-md-12 mb-4'>
          <InputLabel label={t('questionForm.optionModal.formTitle')} />
          <FormTextInput
            placeholder={t('questionForm.optionModal.titlePlaceHolder')}
            value={payload.title || ''}
            handleChange={(e: any) => handleChange('title', e.target.value)}
            required
          />
          {errors.title && <span className='fieldError'>{errors.title}</span>}
        </div>
        <div className='col-md-12 mb-4'>
          <InputLabel label={t('questionForm.optionModal.recommendation')} />
          <Editor
            content={payload.recommendation || ''}
            handleChange={(value: string) =>
              handleChange('recommendation', value)
            }
          />
        </div>

        {questionType !== QuestionType.MultipleChoice && (
          <Direction
            followUpType={payload.followUpType}
            direction={payload.direction}
            handleChange={handleChange}
            error={errors.direction}
          />
        )}

        <div className='col-md-12 mb-4'>
          <InputLabel label={t('questionForm.optionModal.image')} />
          <ImageLoader
            content={payload.image}
            handleChange={(value: ImageResponseDto | undefined) => {
              handleChange('image', value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OptionForm;
