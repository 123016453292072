import React, { useContext, createContext, useState } from 'react';
import { AuthService } from '../services';
import { SinginRequestDto, SinginResponseDto } from '../common/dto';

interface IAuthContext {
  isLoggedIn: boolean;
  signin({ email, password }: SinginRequestDto): Promise<SinginResponseDto>;
  signout(): void;
}

const authContext = createContext<IAuthContext>(null!);

function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth(): IAuthContext {
  const [isLoggedIn, setiIsLoggedIn] = useState(AuthService.isAuthenticated());

  const signin = async ({ email, password }: SinginRequestDto) => {
    return AuthService.signin({ email, password }).then(response => {
      setiIsLoggedIn(true);
      return response;
    });
  };

  const signout = () => {
    AuthService.signout();
    setiIsLoggedIn(false);
  };

  return {
    isLoggedIn,
    signin,
    signout,
  };
}

export { useAuth, ProvideAuth };
