import React from 'react';

interface IInputLabelProps {
  label: string;
  additionalLabel?: string;
}

const InputLabel = ({ label, additionalLabel }: IInputLabelProps) => {
  return (
    <label className='fw-bold mb-1'>
      <span className='text-capitalize'>{label}</span>
      {additionalLabel && (
        <span className='additionalLabel-input'> ({additionalLabel}) </span>
      )}
    </label>
  );
};

export default InputLabel;
