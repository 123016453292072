import React, { useState, useEffect } from 'react';

export type ICustomSelectItem = {
  value: string;
  label: string;
};

type ICustomSelectProps = {
  placeholder: string;
  value?: string;
  notFoundLabel?: string;
  list: Array<ICustomSelectItem>;
  filteredList?: Array<ICustomSelectItem>;
  handleChange: Function;
};

const CustomSelect: React.FunctionComponent<ICustomSelectProps> = ({
  placeholder,
  value,
  list,
  filteredList = list,
  notFoundLabel = 'NOT FOUND',
  handleChange,
}) => {
  const [isOpen, setOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    value ? value : undefined,
  );

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (item: ICustomSelectItem) => {
    setSelectedValue(item.value);
    setOpen(false);
    handleChange(item);
  };

  let selectedLabel = placeholder;
  if (selectedValue) {
    const find = list.find(
      (item: ICustomSelectItem) => item.value === selectedValue,
    );
    selectedLabel = find ? find.label : notFoundLabel;
  }

  return (
    <div
      className={`dropdown ${selectedValue !== null && 'dropdown--selected'} ${
        isOpen && 'dropdown--active'
      }`}
    >
      <div className='dropdownHeader' onClick={toggleDropdown}>
        {selectedLabel}

        {filteredList.length > 0 &&
          (isOpen ? (
            <i className='bi bi-chevron-up'></i>
          ) : (
            <i className='bi bi-chevron-down'></i>
          ))}
      </div>
      {isOpen && filteredList.length > 0 && (
        <div className='dropdown_items scrollbar'>
          {filteredList.map((item: ICustomSelectItem) => (
            <div
              onClick={e => handleItemClick(item)}
              className={`${
                item.value === selectedValue && 'dropdownItem--active'
              }`}
              key={item.value}
            >
              <label className='dropdownItem'>{item.label}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
