class TokenService {
  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  removeToken(): void {
    localStorage.removeItem('token');
  }

  getLocalAccessToken(): string | null {
    const token = localStorage.getItem('token');
    return token;
  }
}

export default new TokenService();
