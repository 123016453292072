import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListQuestionTypes } from '../../common/list';
import { QuestionResponseDto } from '../../common/dto';

interface ITableProps {
  list: Array<QuestionResponseDto>;
  noLeads: Array<string>;
  onDelete: Function;
  onEdit: Function;
}

const QuestionTable = ({ list, noLeads, onDelete, onEdit }: ITableProps) => {
  const { t } = useTranslation();

  const renderListItem = (value: string) => {
    const item = ListQuestionTypes.filter(i => i.value === value)[0];
    return item ? item.label : '-not found-';
  };

  return (
    <table className='dataTable px-3 questionTable'>
      <thead>
        <tr className='trow-header fw-bold'>
          <td className='secondaryTitle-bold py-3' style={{ width: '60%' }}>
            {t('questionList.table.title')}
          </td>
          <td className='secondaryTitle-bold py-3' style={{ width: '15%' }}>
            {t('questionList.table.type')}
          </td>
          <td className='secondaryTitle-bold py-3' style={{ width: '15%' }}>
            {t('questionList.table.section')}
          </td>
          <td className='td-action py-3' style={{ width: '10%' }}></td>
        </tr>
      </thead>
      <tbody>
        {list.map((item: QuestionResponseDto, j) => {
          const isNoLead =
            noLeads.includes(item.id) ||
            !item.options ||
            item.options.length === 0;

          return (
            <tr
              className={`trow-content ${isNoLead ? 'isNoLead' : ''}`}
              key={`tr-${item.id}`}
            >
              <td className='secondaryTitle-bold py-3'>
                ({item.publicId}) {item.title}
              </td>
              <td className='secondaryTitle-bold py-3'>
                {renderListItem(item.type)}
              </td>
              <td className='secondaryTitle-bold py-3'>
                {item.section && item.section.title}
              </td>
              <td className='py-3 d-flex justify-content-between'>
                <span className='editLink' onClick={() => onEdit(j)}>
                  <i className='bi bi-pencil-fill'></i>{' '}
                  {t('questionList.table.editBtn')}
                </span>
                <span className='deleteLink' onClick={() => onDelete(j)}>
                  <i className='bi bi-trash-fill'></i>{' '}
                  {t('questionList.table.deleteBtn')}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default QuestionTable;
