import { HashRouter as Router, Switch } from 'react-router-dom';
import { routeConfigurations } from './appRouting';
import { ProvideAuth } from './providers';

import Alert from 'react-s-alert';

import './i18n/config';

function App() {
  return (
    <ProvideAuth>
      <Alert stack={{ limit: 3 }} position='bottom-right' />
      <Router>
        <Switch>
          {routeConfigurations.map((route, i) => {
            return <route.type {...route} key={i} />;
          })}
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

export default App;
