import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type IEditorProps = {
  content: string;
  handleChange: (value: string) => void;
};

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold'],
    [{ list: 'bullet' }],
    ['link'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const Editor: React.FunctionComponent<IEditorProps> = ({
  content,
  handleChange,
}) => {
  return (
    <div>
      <ReactQuill
        theme='snow'
        value={content}
        onChange={value => handleChange(value)}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
