import React from 'react';
import { useTranslation } from 'react-i18next';

// DTO
import { FollowupType } from '../../common/dto';

// UI COMPONENTS
import { InputLabel } from '../../components';

import EntitySelect from '../../components/EntitySelect/EntitySelect';
import { ICustomSelectItem } from '../../components/CustomSelect';

interface IDirectionProps {
  handleChange: Function;
  followUpType: FollowupType | undefined;
  direction: string | undefined;
  error: string;
}

const Direction = ({
  handleChange,
  followUpType,
  direction,
  error,
}: IDirectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='row'>
        <div className='col-md-12 mb-4'>
          <InputLabel label={t('questionForm.optionModal.followUp')} />
          <div className='d-flex align-items-baseline'>
            <input
              type='radio'
              className='me-3 cp'
              checked={followUpType === FollowupType.Question}
              onChange={(e: any) => {
                handleChange('followUpType', FollowupType.Question);
              }}
            />
            <EntitySelect
              entity='QUESTION'
              value={direction || ''}
              handleChange={(item: ICustomSelectItem) => {
                handleChange('direction', item);
              }}
            />
          </div>
          {error && <span className='fieldError'>{error}</span>}
          <div className='text-center m-3'>
            {t('questionForm.optionModal.or')}
          </div>
          <input
            type='radio'
            className='me-3 cp'
            checked={followUpType === FollowupType.End}
            onChange={(e: any) => {
              handleChange('followUpType', FollowupType.End);
            }}
          />{' '}
          {t('questionForm.optionModal.end')}
        </div>
      </div>
    </>
  );
};

export default Direction;
