import { useHistory } from 'react-router';

function PageNotFound() {
  const history = useHistory();

  const handleOnClick = () => {
    history.push('/');
  };

  return (
    <div className='h-100 d-flex flex-row align-items-center'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-12 text-center'>
            <span className='display-1 d-block'>404</span>
            <div className='mb-4 lead'>
              The page you are looking for was not found.
            </div>
            <button className='btn btn-link' onClick={handleOnClick}>
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
