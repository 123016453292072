import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type TextInputProps<T extends FieldValues = FieldValues> = InputProps &
  UseControllerProps<T> & {
    label?: string;
    error?: string;
  };

function TextInput<T>(props: TextInputProps<T>) {
  const { field, fieldState } = useController(props);

  return (
    <>
      {props.label && (
        <label htmlFor={props.id} className='form-label'>
          {props.label}
        </label>
      )}
      <input
        {...props}
        {...field}
        className={`form-control ${fieldState.invalid ? 'is-invalid' : ''}`}
      />
      {fieldState.invalid && props.error && (
        <div className='invalid-feedback'>{props.error}</div>
      )}
    </>
  );
}

export default TextInput;
