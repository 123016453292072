import { AxiosResponse } from 'axios';

import { BaseApiService } from './index';
import { SectionResponseDto } from '../common/dto';

class SectionService {
  async list(): Promise<AxiosResponse<SectionResponseDto[]>> {
    return BaseApiService.get('/api/section/');
  }
}

export default new SectionService();
