import {
  CreateQuestionRequestDto,
  OptionDto,
  FollowupType,
  QuestionType,
} from './../common/dto';

export type ErrorObjectType = {
  readonly [key: string]: string;
};

export type ValidationReturn = {
  readonly isValid: boolean;
  readonly errors: ErrorObjectType;
};

export const questionValidate = (
  payload: CreateQuestionRequestDto,
): ValidationReturn => {
  const errors: ErrorObjectType = {
    ...(!payload.section ? { section: 'Section shoud be entered' } : {}),
    ...(!payload.title ? { title: 'Title shoud be entered' } : {}),
    ...(!payload.type ? { type: 'Type shoud be entered' } : {}),
    ...(payload.type === QuestionType.MultipleChoice &&
    (!payload.followUpType ||
      (payload.followUpType === FollowupType.Question && !payload.direction))
      ? { direction: 'Direction should be entered' }
      : {}),
  };

  return { isValid: Object.values(errors).length === 0, errors: errors };
};

export const optionValidate = (
  questionPayload: CreateQuestionRequestDto,
  payload: OptionDto,
): ValidationReturn => {
  const errors: ErrorObjectType = {
    ...(questionPayload.type !== QuestionType.MultipleChoice &&
    payload.followUpType === FollowupType.Question &&
    !payload.direction
      ? { direction: 'Direction should be entered' }
      : {}),
    ...(!payload.title ? { title: 'Title shoud be entered' } : {}),
  };

  return { isValid: Object.values(errors).length === 0, errors: errors };
};
