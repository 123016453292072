import React from 'react';
import { useTranslation } from 'react-i18next';

interface ISearchField {
  onSearch: Function;
}

const SearchField = ({ onSearch }: ISearchField) => {
  const { t } = useTranslation();
  return (
    <>
      <input
        className={`form-control custom-input custom-input-search`}
        type='text'
        placeholder={t('searchField.placeholder')}
        onChange={e => onSearch(e.target.value)}
      />
    </>
  );
};

export default SearchField;
