import React from 'react';

import Sidebar from '../../sidebar/sidebar';
import Header from './Header';

const LayoutPrivate: React.FC = (props: { children?: React.ReactNode }) => {
  return (
    <div className='private_app'>
      <Sidebar />
      <div className='private_main'>
        <Header />
        {props.children}
      </div>
    </div>
  );
};

export default LayoutPrivate;
