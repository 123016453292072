import { AxiosResponse } from 'axios';

import { BaseApiService } from './index';

import {
  QuestionResponseDto,
  QuestionListResponseDto,
  CreateQuestionRequestDto,
} from '../common/dto';

class QuestionService {
  async list(query: string): Promise<AxiosResponse<QuestionListResponseDto>> {
    let qURI = '';
    if (query && query !== undefined) {
      qURI = encodeURIComponent(query);
    }
    return BaseApiService.get(`/api/question/?q=${qURI}`);
  }

  async get(id: string): Promise<AxiosResponse<QuestionResponseDto>> {
    return BaseApiService.get('/api/question/' + id);
  }

  async create(
    payload: CreateQuestionRequestDto,
  ): Promise<AxiosResponse<QuestionResponseDto>> {
    return BaseApiService.post<QuestionResponseDto>('/api/question/', payload);
  }

  async update(
    id: string,
    payload: CreateQuestionRequestDto,
  ): Promise<AxiosResponse<QuestionResponseDto>> {
    return BaseApiService.patch<QuestionResponseDto>(
      '/api/question/' + id,
      payload,
    );
  }

  async delete(id: string): Promise<AxiosResponse<void>> {
    return BaseApiService.delete('/api/question/' + id);
  }
}

export default new QuestionService();
