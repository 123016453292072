import React from 'react';
import ReactDOM from 'react-dom';

import './assets/css/bootstrap.min.css';
import './assets/css/common.css';
import './assets/css/layout.css';
import './assets/css/modal.css';
import './assets/css/table.css';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-s-alert/dist/s-alert-default.css';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
