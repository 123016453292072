import React from 'react';

// UI COMPONENTS
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

interface IAbstractModalProps {
  label?: string;
  children?: React.ReactNode;
  cancelButton?: { onClick: Function };
  leftBtn?: {
    label: string;
    icon?: string;
    onClick: Function;
  };
  rightBtn?: {
    label: string;
    icon?: string;
    onClick: Function;
  };
  toDelete?: boolean;
  className?: string;
}

const AbstractModal = ({
  label,
  children,
  cancelButton,
  leftBtn,
  rightBtn,
  toDelete,
  className,
}: IAbstractModalProps) => {
  return (
    <div
      className={`modal show ${className}`}
      id='exampleModal'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-dialog-centered modal-xl'
        role='document'
      >
        <div className='modal-content'>
          <div
            className={`modal-header justify-content-between ${
              toDelete && 'modalHeader'
            }`}
          >
            <div className='modalTitle h5' id='exampleModalLabel'>
              {label}
            </div>
            {cancelButton && (
              <div className='close' onClick={() => cancelButton.onClick()}>
                <i className='bi bi-x fs-4 cancelButton'></i>
              </div>
            )}
          </div>

          <div className={`modal-body ${toDelete && 'modalBodyDelete'}`}>
            {children}
          </div>

          <div
            className='modal-footer py-4'
            style={{ justifyContent: toDelete ? 'center' : '' }}
          >
            {leftBtn && (
              <SecondaryButton
                className='secondaryButton-blue'
                title={leftBtn.label}
                icon={leftBtn.icon}
                onClick={() => leftBtn.onClick()}
              />
            )}

            {rightBtn && (
              <PrimaryButton
                title={rightBtn.label}
                icon={rightBtn.icon}
                onClick={() => rightBtn.onClick()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbstractModal;
