import { AxiosResponse } from 'axios';

import { BaseApiService } from './index';
import { ImageResponseDto } from '../common/dto';

class ImageService {
  async upload(formData: any): Promise<AxiosResponse<ImageResponseDto>> {
    return BaseApiService.post('/api/image/upload', formData);
  }

  async delete(key: string): Promise<AxiosResponse<void>> {
    return BaseApiService.delete('/api/image/' + key);
  }
}

export default new ImageService();
