import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './providers';
import { LayoutPublic, LayoutPrivate } from './components';

import {
  Main,
  Signin,
  PageNotFound,
  QuestionList,
  QuestionView,
} from './pages/index';

export interface IRouteConfiguration {
  type: typeof PrivateRoute | typeof PublicRoute;
  component: React.FC<any>;
  path: string;
  exact?: boolean;
}

const PublicRoute = ({
  component: Component,
  exact,
  path,
}: IRouteConfiguration) => {
  const { isLoggedIn } = useAuth();

  // Default redirection from public routes to panel
  if (isLoggedIn && ['/', '/signin'].includes(path)) {
    return <Redirect to='/questions' />;
  }

  return (
    <Route
      exact={exact}
      key={path}
      path={path}
      render={props => (
        <LayoutPublic>
          <Component {...props} />
        </LayoutPublic>
      )}
    />
  );
};

const PrivateRoute = ({
  component: Component,
  exact,
  path,
}: IRouteConfiguration) => {
  const { isLoggedIn: isLogedIn } = useAuth();

  // IF Logged in
  if (!isLogedIn) {
    return <Redirect to='/signin' />;
  }

  return (
    <Route
      exact={exact}
      key={path}
      path={path}
      render={props => (
        <LayoutPrivate>
          <Component {...props} />
        </LayoutPrivate>
      )}
    />
  );
};

export const routeConfigurations: IRouteConfiguration[] = [
  {
    exact: true,
    path: '/',
    component: Main,
    type: PublicRoute,
  },
  {
    path: '/questions',
    component: QuestionList,
    type: PrivateRoute,
  },
  {
    path: '/question/:id',
    component: QuestionView,
    type: PrivateRoute,
  },
  {
    path: '/question',
    component: QuestionView,
    type: PrivateRoute,
  },

  // Auth routes
  {
    path: '/signin',
    component: Signin,
    type: PublicRoute,
  },

  // ELSE - NOT FOUND
  {
    path: '/*',
    component: PageNotFound,
    type: PublicRoute,
  },
];
