import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers';
import { TextInput } from '../../components';
import { EMAIL_PATTERN } from '../../utils/pattern';

import logo from '../../assets/icons/logo.svg';

interface FormInputs {
  email: string;
  password: string;
}

function Singin() {
  const { t } = useTranslation();
  const auth = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    setError,
  } = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (data: FormInputs) => {
    auth.signin(data).catch(() => {
      setError('email', { type: 'response' }, { shouldFocus: true });
      setError('password', { type: 'response' });
    });
  };

  return (
    <div className='row h-100 align-items-center justify-content-center signIn'>
      <div className='col-12 col-sm-8 col-md-4 pb-5'>
        <div className='card'>
          <img src={logo} alt='logo' />
          <div className='card-header text-center'>{t('signin.title')}</div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className='mb-3'>
                <TextInput
                  id='email'
                  control={control}
                  name='email'
                  type='email'
                  label={t('signin.email.label')}
                  error={errors.email?.message}
                  rules={{
                    required: String(t('signin.email.required')),
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: t('signin.email.validation'),
                    },
                  }}
                />
              </div>
              <div className='mb-3'>
                <TextInput
                  id='password'
                  control={control}
                  name='password'
                  type='password'
                  label={t('signin.password.label')}
                  error={errors.password?.message}
                  rules={{
                    required: String(t('signin.password.required')),
                    minLength: {
                      value: 8,
                      message: t('signin.password.validation'),
                    },
                  }}
                />
              </div>
              {isSubmitted &&
                (errors.email?.type.includes('response') ||
                  errors.password?.type.includes('response')) && (
                  <p className='text-danger'>{t('signin.error')}</p>
                )}

              <div className='text-center'>
                <button type='submit' className='btn btn-primary'>
                  {t('signin.button')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Singin;
