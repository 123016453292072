import { BaseApiService, TokenService } from './index';
import { SinginRequestDto, SinginResponseDto } from '../common/dto';

class AuthService {
  isAuthenticated(): boolean {
    // TODO change to get from user data
    return Boolean(TokenService.getLocalAccessToken());
  }

  async signin({
    email,
    password,
  }: SinginRequestDto): Promise<SinginResponseDto> {
    return BaseApiService.post<SinginResponseDto>('/auth/login', {
      email,
      password,
    }).then(response => {
      if (response.data.access_token) {
        TokenService.setToken(response.data.access_token);
      }

      return response.data;
    });
  }

  signout(): boolean {
    TokenService.removeToken();
    return true;
  }
}

export default new AuthService();
