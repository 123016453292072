import React from 'react';

interface IPrimaryButtonProps {
  type?: 'button' | 'submit' | 'reset';
  title: string;
  icon?: string;
  className?: string;
  disabled?: boolean | undefined;
  onClick?: () => void;
}

const PrimaryButton = ({
  type,
  title,
  icon,
  className = '',
  disabled,
  onClick,
}: IPrimaryButtonProps) => {
  return (
    <button
      type={type ? type : 'button'}
      className={`btn primaryButton ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <i className={icon}></i>}
      <span className='text-uppercase px-1'>{title}</span>
    </button>
  );
};

export default PrimaryButton;
