import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import Alert from 'react-s-alert';
import {
  CreateQuestionRequestDto,
  FollowupType,
  OptionDto,
  QuestionType,
} from '../../common/dto';

import { ListQuestionTypes } from '../../common/list';

// UI COMPONENTS
import {
  AbstractModal,
  FormTextInput,
  InputLabel,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import EntitySelect from '../../components/EntitySelect/EntitySelect';
// SERVICE
import { QuestionService } from '../../services';
import {
  ErrorObjectType,
  optionValidate,
  questionValidate,
} from './../../utils/validation';

import OptionForm from './OptionForm';
import Direction from './Direction';

enum COMPONENT_STATE {
  CREATE,
  EDIT,
  NOT_FOUND,
}

enum OPTION_STATE {
  NONE,
  ADD_OPTION,
  EDIT_OPTION,
}

const questionInitialValue = {
  publicId: 0,
  title: '',
  type: QuestionType.SingleChoice,
  section: { id: '', title: '' },
  options: [],
  reachableThru: [],
};

const optionInitialValue = {
  title: '',
  direction: '',
  recommendation: '',
  followUpType: FollowupType.Question,
  image: undefined,
};

const QuestionView = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [optionSelectedIndex, setOptionSelectedIndex] = useState<number>(-1);

  const [state, setState] = useState(COMPONENT_STATE.CREATE);
  const [optionState, setOptionState] = useState(OPTION_STATE.NONE);

  const [data, setData] =
    useState<CreateQuestionRequestDto>(questionInitialValue);

  const [optionData, setOptionData] = useState<OptionDto>(optionInitialValue);
  const [questionErrors, setQuestionErrors] = useState<ErrorObjectType>({});
  const [optionErrors, setOptionErrors] = useState<ErrorObjectType>({});

  useEffect(() => {
    if (id === undefined) {
      setState(COMPONENT_STATE.CREATE);
      // super important, without this change options do not cleanedup on create
      setData(Object.assign({}, questionInitialValue));
      handleChange('options', []);
    } else {
      setState(COMPONENT_STATE.EDIT);
      serviceGet(id);
    }
  }, [id]);

  const serviceGet = (id: string) => {
    QuestionService.get(id)
      .then(r => {
        setData(r.data);
      })
      .catch(e => {
        console.log('e', e);
      });
  };

  const serviceCreate = () => {
    QuestionService.create(data)
      .then(r => {
        setData(r.data);
        Alert.info(t('questionForm.service.created'));
        history.push('/question/' + r.data.id);
      })
      .catch(e => {
        Alert.warning(t('questionForm.service.createdError'));
        console.log('e', e);
      });
  };

  const serviceUpdate = () => {
    QuestionService.update(id, data)
      .then(r => {
        Alert.info(t('questionForm.service.updated'));
      })
      .catch(e => {
        Alert.warning(t('questionForm.service.updatedError'));
        console.log('e', e);
      });
  };

  const handleSubmit = () => {
    const { isValid, errors } = questionValidate(data);
    if (!isValid) {
      setQuestionErrors(errors);
      return;
    }

    if (state === COMPONENT_STATE.CREATE) {
      serviceCreate();
    }
    if (state === COMPONENT_STATE.EDIT) {
      serviceUpdate();
    }
  };

  // Question Form Change
  const handleChange = (fieldName: string, value: any) => {
    const newData = Object.assign({}, data);

    if (fieldName === 'title') newData.title = value;
    if (fieldName === 'publicId') newData.publicId = value;
    if (fieldName === 'section') newData.section = value;
    if (fieldName === 'type') newData.type = value;
    if (fieldName === 'options') newData.options = value;

    if (fieldName === 'direction') {
      newData.direction = value.value;
    }
    if (fieldName === 'followUpType') {
      newData.followUpType = value;
      if (value !== FollowupType.Question) {
        newData.direction = undefined;
      }
    }

    setData(newData);
  };

  // Option Form Change
  const optionHandleChange = (fieldName: string, value: any) => {
    const newData = Object.assign({}, optionData);
    if (fieldName === 'title') newData.title = value;
    if (fieldName === 'recommendation') newData.recommendation = value;
    if (fieldName === 'direction') {
      newData.direction = value.value;
      newData.question = { title: value.label };
    }
    if (fieldName === 'followUpType') {
      newData.followUpType = value;
      if (value !== FollowupType.Question) {
        newData.direction = undefined;
        newData.question = undefined;
      }
    }
    if (fieldName === 'image') newData.image = value;

    setOptionData(newData);
  };

  const deleteOption = (index: number) => {
    const newOptions = data.options;
    newOptions.splice(index, 1);
    handleChange('options', newOptions);
  };

  const editOption = (index: number) => {
    setOptionSelectedIndex(index);
    setOptionData(data.options[index]);
    setOptionState(OPTION_STATE.EDIT_OPTION);
  };

  const optionFormSubmit = () => {
    const newOptions = data.options;
    const { isValid, errors } = optionValidate(data, optionData);

    if (!isValid) {
      setOptionErrors(errors);
      return;
    }

    if (
      optionState === OPTION_STATE.EDIT_OPTION &&
      optionSelectedIndex !== -1
    ) {
      newOptions[optionSelectedIndex] = optionData;
    } else {
      newOptions.push(optionData);
    }

    handleChange('options', newOptions);
    setOptionState(OPTION_STATE.NONE);
  };

  const renderFollowUpType = (option: OptionDto) => {
    if (option.followUpType === FollowupType.Question) {
      return (
        <Link to={'/question/' + option.direction} target='_blank'>
          {option.question && <span>{option.question.title}</span>}
        </Link>
      );
    }

    if (option.followUpType === FollowupType.End) {
      return (
        <span className='greenBorder'>{t('questionForm.optionModal.end')}</span>
      );
    }
  };

  return (
    <div className='private_body pb-2'>
      <div className='private_container'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='fs-5 fw-bold'>{t('questionForm.formTitle')} </div>
          <div className='d-flex align-items-center'>
            <SecondaryButton
              className='secondaryButton-blue me-3'
              title={t('questionForm.backBtn')}
              icon='bi bi-arrow-left-circle'
              onClick={() => {
                history.push('/questions');
              }}
            />
            <PrimaryButton
              title={t('questionForm.saveBtn')}
              icon='bi bi-check2-circle'
              className='me-3'
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </div>

      <div className='private_container'>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-md-12 mb-4'>
              <InputLabel label={t('questionForm.section')} />
              <EntitySelect
                entity='SECTION'
                value={data.section && data.section.id}
                handleChange={(item: ICustomSelectItem) => {
                  handleChange('section', {
                    id: item.value,
                    title: item.label,
                  });
                }}
              />
              {questionErrors.sectionId && (
                <span className='fieldError'>{questionErrors.section}</span>
              )}
            </div>

            <div className='col-md-12 mb-4'>
              <InputLabel label={t('questionForm.title')} />
              <FormTextInput
                placeholder={t('placeholders.title')}
                value={data.title || ''}
                handleChange={(e: any) => handleChange('title', e.target.value)}
                required
              />
              {questionErrors.title && (
                <span className='fieldError'>{questionErrors.title}</span>
              )}
            </div>

            <div className='col-md-12 mb-4'>
              <InputLabel label={t('questionForm.publicId')} />
              <FormTextInput
                placeholder={t('placeholders.publicId')}
                value={data.publicId || ''}
                handleChange={(e: any) =>
                  handleChange('publicId', e.target.value)
                }
                required
              />
            </div>

            <div className='col-md-12 mb-4'>
              <InputLabel label={t('questionForm.type')} />
              <CustomSelect
                placeholder={t('placeholders.type')}
                list={ListQuestionTypes}
                value={data.type}
                handleChange={(item: ICustomSelectItem) => {
                  handleChange('type', item.value);
                }}
              />
              {questionErrors.type && (
                <span className='fieldError'>{questionErrors.type}</span>
              )}
            </div>

            {data.type === QuestionType.MultipleChoice && (
              <Direction
                followUpType={data.followUpType}
                direction={data.direction}
                handleChange={handleChange}
                error={questionErrors.direction}
              />
            )}

            <div className='col-md-12 mb-4'>
              <InputLabel label={t('questionForm.options')} />
              <div className='custom-input p-3'>
                {data.options.map((option: OptionDto, j) => {
                  return (
                    <div className='optionContainer' key={`option-${j}`}>
                      <div className='optionLeft'>
                        <div className='title'>{option.title}</div>
                        {option.recommendation && (
                          <span className='greenBorder'>
                            {t('questionForm.recommendation')}
                          </span>
                        )}
                        {data.type !== QuestionType.MultipleChoice && (
                          <>
                            <div className='bi bi-arrow-down-circle' />
                            {renderFollowUpType(option)}
                          </>
                        )}
                      </div>
                      <div>
                        <span
                          className='edit'
                          onClick={() => {
                            editOption(j);
                          }}
                        >
                          <i className='bi bi-pencil-square' />
                          {t('questionForm.optionEdit')}
                        </span>
                        <span
                          className='delete'
                          onClick={() => {
                            deleteOption(j);
                          }}
                        >
                          <i className='bi bi-trash' />
                          {t('questionForm.optionDelete')}
                        </span>
                      </div>
                    </div>
                  );
                })}
                <PrimaryButton
                  title={t('questionForm.addOption')}
                  icon='bi bi-plus-circle'
                  className='me-3'
                  onClick={() => {
                    setOptionData(optionInitialValue);
                    setOptionErrors({});
                    setOptionState(OPTION_STATE.ADD_OPTION);
                  }}
                />
                {(!data.options || data.options.length === 0) && (
                  <div className='block-error'>
                    {t('questionForm.noOptions')}
                  </div>
                )}
              </div>
            </div>

            {state === COMPONENT_STATE.EDIT && (
              <div className='col-md-12 mb-4'>
                <InputLabel label={t('questionForm.reachableThru')} />
                <div className='custom-input p-3'>
                  {data.reachableThru && data.reachableThru.length > 0 ? (
                    <ul>
                      {data.reachableThru.map((i, j) => {
                        return (
                          <li key={'li-' + j}>
                            <Link to={'/question/' + i.id} target='_blank'>
                              {i.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className='block-error'>
                      {t('questionForm.noLeads')}
                    </div>
                  )}
                </div>
              </div>
            )}

            {(optionState === OPTION_STATE.EDIT_OPTION ||
              optionState === OPTION_STATE.ADD_OPTION) && (
              <AbstractModal
                label={t('questionForm.optionModal.formTitle')}
                leftBtn={{
                  label: t('questionForm.optionModal.cancelBtn'),
                  onClick: () => {
                    setOptionState(OPTION_STATE.NONE);
                    setOptionData(optionInitialValue);
                    setOptionErrors({});
                  },
                }}
                rightBtn={{
                  label: t('questionForm.optionModal.saveBtn'),
                  onClick: () => {
                    optionFormSubmit();
                  },
                }}
              >
                <OptionForm
                  questionType={data.type}
                  payload={optionData}
                  errors={optionErrors}
                  handleChange={optionHandleChange}
                />
              </AbstractModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionView;
