export interface SinginRequestDto {
  email: string;
  password: string;
}

export interface SinginResponseDto {
  access_token: string;
}

export interface SectionResponseDto {
  id: string;
  title: string;
}

export enum QuestionType {
  Boolean = 'question:type:yesNo',
  SingleChoice = 'question:type:singleChoice',
  MultipleChoice = 'question:type:multipleChoice',
}

export interface QuestionResponseDto {
  id: string;
  publicId: number;
  title: string;
  type: QuestionType;
  section: any;
  options: OptionDto[];
  reachableThru: any[];
}

export interface QuestionListResponseDto {
  list: QuestionResponseDto[];
  noLeads: string[];
}

export interface CreateQuestionRequestDto {
  publicId: number;
  title: string;
  type: QuestionType;
  section: SectionResponseDto;
  options: OptionDto[];
  reachableThru: any[];
  followUpType?: FollowupType;
  direction?: string;
}

export enum FollowupType {
  Question = 'followup:type:question',
  End = 'followup:type:end',
}

export interface OptionDto {
  title: string;
  recommendation?: string;
  followUpType: FollowupType;
  direction?: string;
  question?: { title: string };
  image?: ImageResponseDto;
}

export interface ImageResponseDto {
  location: string;
  key: string;
}
