import React from 'react';

interface ISecondaryButtonProps {
  type?: 'button' | 'submit' | 'reset';
  title: string;
  icon?: string;
  className?: string;
  onClick?: () => void;
}

const SecondaryButton = ({
  type,
  title,
  icon,
  onClick,
  className,
}: ISecondaryButtonProps) => {
  return (
    <button
      type={type ? type : 'button'}
      className={`btn secondaryButton ${className}`}
      onClick={onClick}
    >
      {icon && <i className={icon}></i>}
      <span className='text-uppercase px-1'>{title}</span>
    </button>
  );
};

export default SecondaryButton;
