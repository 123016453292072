import React from 'react';

interface ITextInputProps {
  inputType?: string;
  value: string | number;
  placeholder?: string;
  handleChange: any;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  minValue?: string;
  maxValue?: string;
}

const FormTextInput = ({
  inputType,
  value,
  placeholder,
  handleChange,
  error,
  required,
  disabled,
  minValue,
  maxValue,
}: ITextInputProps) => {
  return (
    <>
      <input
        className={`form-control custom-input ${error && 'input-error'}`}
        type={inputType ? inputType : 'text'}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
        min={minValue}
        max={maxValue}
        disabled={disabled}
      />
      {error && <span className='text-uppercase error-text pt-1'>{error}</span>}
    </>
  );
};

export default FormTextInput;
