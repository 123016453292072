import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../providers';
import logo from '../../assets/icons/logo.svg';

const Sidebar: React.FC = () => {
  const { t } = useTranslation();

  const auth = useAuth();

  const handleOnSignout = () => {
    auth.signout();
  };

  return (
    <div className='sidebar-wrapper'>
      <div className='sidebar-top px-4'>
        <b>{t('menu.brandName')}</b>
        <img src={logo} alt='logo' />
      </div>

      <div className='px-4 pt-4'>
        <div className='mb-5'>
          <div className='uppercaseTitle fw-bold'>{t('menu.menu')}</div>

          <NavLink
            className='navLink my-4'
            activeClassName='navLink--active'
            to='/questions'
          >
            <i className={`bi bi-list navLink_icon`}></i>
            <span className='mx-2'>{t('menu.questions')}</span>
          </NavLink>

          <NavLink
            className='navLink my-4'
            activeClassName='navLink--active'
            onClick={handleOnSignout}
            to='#'
          >
            <i className={`bi bi-door-closed navLink_icon`}></i>
            <span className='mx-2'>{t('menu.logout')}</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
