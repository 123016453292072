import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Alert from 'react-s-alert';

// SERVICE
import { QuestionService } from '../../services';
import { QuestionResponseDto } from '../../common/dto';

// MODAL
import AbstractModal from '../../components/AbstractModal';

// UI COMPONENTS
import { PrimaryButton, SearchField } from '../../components';

import QuestionTable from './QuestionTable';

enum COMPONENT_STATE {
  LIST,
  DELETE,
}

const QuestionList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [state, setState] = useState(COMPONENT_STATE.LIST);
  const [list, setList] = useState<QuestionResponseDto[]>([]);
  const [noLeads, setNoLeads] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    serviceList(search);
  }, [search]);

  const serviceList = (query: string) => {
    QuestionService.list(query)
      .then(r => {
        setList(r.data.list);
        setNoLeads(r.data.noLeads);
      })
      .catch(e => {
        console.log('e', e);
      });
  };

  const serviceDelete = (id: string) => {
    QuestionService.delete(id)
      .then(() => {
        Alert.info(t('questionList.serviceDelete'));
        serviceList(search);
        setState(COMPONENT_STATE.LIST);
      })
      .catch(e => {
        console.log('e', e);
      });
  };

  return (
    <div className='private_body pb-2'>
      <div className='private_container'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='fs-5 fw-bold'>
            {t('questionList.title')}{' '}
            <span className='secondaryTitle-bold'>({list.length})</span>
          </div>

          <div className='d-flex align-items-center'>
            <div className='me-4'>
              <SearchField
                onSearch={(value: string) => {
                  setSearch(value);
                }}
              />
            </div>
            <PrimaryButton
              title={t('questionList.createBtn')}
              icon='bi bi-plus-circle'
              onClick={() => {
                history.push('/question');
              }}
            />
          </div>
        </div>
      </div>

      <QuestionTable
        list={list}
        noLeads={noLeads}
        onDelete={(index: number) => {
          setSelectedIndex(index);
          setState(COMPONENT_STATE.DELETE);
        }}
        onEdit={(index: number) => {
          history.push('/question/' + list[index].id);
        }}
      />

      {state === COMPONENT_STATE.DELETE && (
        <AbstractModal
          label={t('questionList.deleteModal.title')}
          leftBtn={{
            label: t('questionList.deleteModal.noDismiss'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('questionList.deleteModal.yesDelete'),
            onClick: () => {
              serviceDelete(list[selectedIndex].id);
            },
          }}
        >
          <div className='text-center'>
            {t('questionList.deleteModal.message')} "{list[selectedIndex].title}
            "?
          </div>
        </AbstractModal>
      )}
    </div>
  );
};

export default QuestionList;
