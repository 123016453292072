import React, { useState, useEffect } from 'react';

// SERVICE
import QuestionService from './../../services/question.service';
import SectionService from './../../services/section.service';

import CustomSelect, { ICustomSelectItem } from './../CustomSelect';

export interface IEntitySelectItem {
  value: string | undefined;
}

interface IEntitySelectProps {
  entity: string;
  value: string;
  handleChange: Function;
}

interface IServiceConfiguration {
  service: { method: Function };
  notFoundLabel: string;
  placeholder: string;
  listModify: Function;
}

interface IServiceConfigurationList {
  [key: string]: IServiceConfiguration;
}

const serviceByEntity: IServiceConfigurationList = {
  QUESTION: {
    service: { method: QuestionService.list },
    placeholder: 'Please select',
    notFoundLabel: '-QUESTION NOT FOUND-',
    listModify: (list: Array<any>) => {
      return list.map(item => {
        const label = `${item.publicId} - ${item.title} `;
        return { value: item.id, label };
      });
    },
  },
  SECTION: {
    service: { method: SectionService.list },
    placeholder: 'Please select',
    notFoundLabel: '-SECTION NOT FOUND-',
    listModify: (list: Array<any>) => {
      return list.map(item => {
        return { value: item.id, label: item.title };
      });
    },
  },
};

export const EntitySelect = ({
  entity,
  value,
  handleChange,
}: IEntitySelectProps) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<any[]>([]);

  const fetchList = (service: any) => {
    service.method().then((r: any) => {
      setLoading(false);

      if (entity === 'QUESTION') {
        setList(r.data.list);
      }

      if (entity === 'SECTION') {
        setList(r.data);
      }
    });
  };

  const entityItem = serviceByEntity[entity];

  useEffect(() => {
    fetchList(entityItem.service);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading === false && (
        <CustomSelect
          placeholder={entityItem.placeholder}
          notFoundLabel={entityItem.notFoundLabel}
          list={entityItem.listModify(list)}
          value={value}
          handleChange={(item: ICustomSelectItem) => {
            handleChange(item);
          }}
        />
      )}
    </>
  );
};

export default EntitySelect;
